<template>
    <div class="container">
        <div class="content">
            <div class="left">
                <div class="export">
                    <el-button type="primary" size="small" @click="exportQuenstion()">导入试题</el-button>
                    <a href="javascript:;" @click="exportHistory()">导入记录</a>
                </div>
                <div class="tree">
                    <div class="top">
                        <span class="color-sys">知识点列表</span>

                    </div>
                    <div class="tree-list">
                        <el-tree :data="treeData" show-checkbox ref='tree' node-key='id' check-strictly
                            @check='checkGroupNode' default-expand-all check-on-click-node getCurrentNode
                            :props="defaultProps">
                            <span class="custom-tree-node" slot-scope="{data}">
                                <span :title="data.name" class="tree-node-name">
                                    {{ data.name }}
                                </span>
                            </span>
                        </el-tree>
                    </div>
                </div>
            </div>

            <div class="classify">
                <div class="search">
                    <div class="type">
                        <span class="name">
                            题型
                        </span>
                        <div class="options">
                            <!-- <span v-for="(item, index) in questionsType" :key="index"
                                :class="{ 'active': index == options.type }" @click="changeSearch(index, 'type')">{{
                                    item.name }}
                            </span> -->
                            <el-radio-group v-model="options.type" @input="changeSearch">
                                <el-radio :label="index" v-for="(item, index) in questionsType" :key="index">{{
                                    item.name }}</el-radio>
                            </el-radio-group>
                        </div>
                    </div>

                    <div class="type">
                        <span class="name">
                            难度
                        </span>
                        <div class="options">
                            <!-- <span v-for="(item, index) in difficultyType" :key="index"
                                :class="{ 'active': index == options.difficulty }"
                                @click="changeSearch(index, 'difficulty')">{{ item.name }}
                            </span> -->
                            <el-radio-group v-model="options.difficulty" @input="changeSearch">
                                <el-radio :label="index" v-for="(item, index) in difficultyType" :key="index">{{
                                    item.name }}</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="type">
                        <span class="name">
                            一致性
                        </span>
                        <div class="options">
                            <!-- <span v-for="(item, index) in consistencyType" :key="index"
                                :class="{ 'active': index == options.consistency }"
                                @click="changeSearch(index, 'consistency')">{{ item.name }}
                            </span> -->
                            <el-radio-group v-model="options.consistency" @input="changeSearch">
                                <el-radio :label="index" v-for="(item, index) in consistencyType" :key="index">{{
                                    item.name }}</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="type">
                        <span class="name">
                            等级
                        </span>
                        <div class="options">
                            <!-- <span v-for="(item, index) in levelType" :key="index"
                                :class="{ 'active': index == options.level }" @click="changeSearch(index, 'level')">{{
                                    item.name }}
                            </span> -->
                            <el-radio-group v-model="options.level" @input="changeSearch">
                                <el-radio :label="index" v-for="(item, index) in levelType" :key="index">{{ item.name
                                    }}</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <p>该条件下共筛选出<span>{{ this.total }}</span>道题</p>
                </div>
                <div class="questions-content">
                    <div class="item" v-for="(item, index) in questionList" :key="index">

                        <div v-if="item.type == 3" class="opions-content">
                            <p><span class="index">{{ index + 1 }}.</span><span>{{ item.context }}</span></p>
                            <p>A、正确 </p>
                            <p>B、错误</p>
                        </div>
                        <div v-else class="opions-content">
                            <p><span class="index">{{ index + 1 }}.</span><span>{{ item.context }}</span></p>
                            <p v-for="(it, index1) in item.optionsObj" :key="index1">
                                {{ it.optionChoise }}、{{ it.optionMessage }}
                            </p>
                        </div>
                        <div class="difficulty">

                            <p>
                                难度: <span class="color-sys mar-right-10">{{ difficultyName[item['difficulty']] }}</span>
                                <!-- 答案:
                                <span v-if="item.type == 3" class="color-sys">{{ item.answer == 'T' ? '正确' : '错误'}}</span>
                                <span v-else class="color-sys">{{ item.answer }}</span> -->
                            </p>
                            <span><a href="javascript:;" @click="edit(item)">编辑</a><a href="javascript:;"
                                    @click="fold(item)">{{
                                        item['flag'] ? '收起' :
                                            '解析' }}</a></span>
                        </div>
                        <div class="parse" v-if="item['flag']">
                            <p style="margin-bottom: 10px;">答案:<span v-if="item.type == 3">{{ item.answer == 'T' ? '正确' : '错误' }}</span><span
                                    v-else>{{ item.answer
                                    }}</span></p>
                            <p style="margin-bottom: 10px;">解析:{{item.analysis? item.analysis : '暂无解析'}}</p>
                            <p>知识点:{{item.knowledgeName}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination" v-if="total > 0">
            <el-pagination background layout="prev, pager, next" :total="total" @size-change="sizeChange()"
                @current-change="currentChange" :current-page="current" :page-size="pageSize">
            </el-pagination>
        </div>

    </div>
</template>

<script>

export default {
    name: 'QuestionManagement',
    data() {
        return {
            user: {},
            tab: [{ name: '试题管理' }, { name: '题库统计' }, { name: '自动组卷' }, { name: '组卷记录' }],
            activeIndex: 0,
            examList: [],
            total: 0,
            current: 1,
            pageSize: 5,
            treeData: [],
            id: '',
            defaultProps: {
                children: 'children',
                label: 'name',
            },
            questionsType: [{ name: '全部' }, { name: '单选题' }, { name: '多选题' }, { name: '判断题' }], // 题型
            difficultyType: [{ name: '全部' }, { name: '容易' }, { name: '较易' }, { name: '一般' }, { name: '较难' }, { name: '困难' }],//难度
            consistencyType: [{ name: '全部' }, { name: '1' }, { name: '2' }, { name: '3' }, { name: '4' }, { name: '5' }],//一致性
            levelType: [{ name: '全部' }, { name: '一级' }, { name: '二级' }, { name: '三级' }],//等级
            options: {
                type: 0,
                difficulty: 0,
                consistency: 0,
                level: 0
            },
            questionList: [],
            path: '',
            difficultyName: ['', '容易', '较易', '一般', '较难', '困难']
        };
    },
    created() {
        this.getKnowledgeList();
        this.getQuestionList();
    },
    methods: {
        go(index, item) {
            this.activeIndex = index;
            this.$router.push(item.route);
        },
        edit(item) {

            this.$router.push(`/admin/import-questions/${item.id}`);
        },
        exportQuenstion() {
            this.$router.push(`/admin/import-questions/-1`);
        },
        exportHistory() {
            this.$router.push(`/admin/import-records`);
        },
        async getKnowledgeList() {
            // 获取知识点树
            const result = await this.Api.databaseManage.getKnowledgeTree({});
            this.treeData = result.data;
        },
        async getQuestionList() {
            let data = {
                "type": this.options.type == 0 ? null : this.options.type,
                "difficulty": this.options.difficulty == 0 ? null : this.options.difficulty,
                "consistency": this.options.consistency == 0 ? null : this.options.consistency,
                "level": this.options.level == 0 ? null : this.options.level,
                "knowledgePath": this.path,
                "pageIndex": this.current,
                "pageSize": this.pageSize
            }


            const result = await this.Api.databaseManage.searchQuestion(data);

            result.data.records.forEach(item => {
                item.optionsObj = JSON.parse(item.options);
                item['flag'] = false;

            });
            this.questionList = result.data.records;
            console.log("🚀 ~ getQuestionList ~ this.questionList:", this.questionList)
            this.total = result.data.total;


        },
        fold(item) {
            console.log("🚀 ~ fold ~ item:", item)
            item['flag'] = !item['flag'];
        },
        changeType(index) {
            if (this.activeIndex == index) return;
            this.activeIndex = index;
            this.current = 1;
            this.getQuestionList();
        },
        changeSearch() {
            this.current = 1;
            this.getQuestionList();
        },
        // changeSearch(value, type) {
        //     if (this.options[type] == value) {
        //         return
        //     }
        //     this.options[type] = value;
        //     this.current = 1;
        //     this.getQuestionList();
        // },

        sizeChange() { },
        currentChange(page) {
            this.current = page;
            this.getQuestionList();
        },

        checkGroupNode: function (a, b) {
   

            if (b.checkedKeys.length > 0) {
                this.$refs.tree.setCheckedKeys([a.id])
                this.id = [a.id];
                this.path = a.path;
                this.current = 1;
                this.getQuestionList();
            }else{
                this.id = [];
                this.path = '';
                this.current = 1;
                this.getQuestionList();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.container {
    width: 100%;
    overflow: hidden;

    .color-sys {
        color: #3bafda;
    }

    .mar-right-10 {
        margin-right: 10px;
    }

    .content {
        margin: 0 20px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;

        .left {
            width: 300px;
            overflow: hidden;

            .export {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0;

                .el-button {
                    background: #3bafda;
                    border-color: #3bafda;
                }

                a {
                    color: #3bafda;
                }
            }

            .tree {
                width: 300px;
                overflow: hidden;
                border: 1px solid #d8d8d8;
                max-height: 500px;
                overflow: auto;



                .top {
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 10px;
                    border-bottom: 1px solid #3bafda;
                    // border-top: 1px solid #3bafda;


                    .btn {
                        width: 100px;
                        height: 28px;
                        display: flex;
                        border-radius: 5px;
                        border: 1px solid #3bafda;
                        font-size: 14px;
                        box-sizing: border-box;

                        span {
                            display: flex;
                            flex: 1;
                            justify-content: center;
                            align-items: center;
                            color: #3bafda;
                            cursor: pointer;

                        }

                        span:first-child {
                            border-right: 1px solid #3bafda;
                        }

                        .active {
                            background: #3bafda;
                            color: #fff;
                        }
                    }
                }

                .tree-node-name {
                    font-size: 13px;
                }
            }
        }



        .classify {
            width: calc(100% - 320px);
            overflow: hidden;

            .search {
                width: 100%;
                box-sizing: border-box;

                .type {
                    display: flex;
                    margin-bottom: 10px;

                    .name {
                        width: 60px;
                        text-align: right;
                        margin-right: 20px;
                        color: #3bafda;
                    }

                    .options {
                        display: flex;

                        span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 80px;
                            padding: 5px 0;
                            margin-right: 10px;
                            box-sizing: border-box;
                            border: 1px solid #d8d8d8;
                            border-radius: 5px;
                            cursor: pointer;
                        }

                        span:hover {
                            border: 1px solid #3bafda;
                        }

                        .active {
                            background: #3bafda;
                            border: 1px solid #3bafda;
                            color: #fff;
                        }
                    }
                }

                p {
                    text-align: right;

                    span {
                        color: #3bafda;
                        font-size: 18px;
                        font-weight: 600;
                    }
                }

            }

            .questions-content {
                width: 100%;
                box-sizing: border-box;
                margin-top: 20px;
                overflow: hidden;

                .item {
                    margin-bottom: 20px;
                    border-radius: 5px;
                    border: 1px solid #d8d8d8;
                    padding-top: 10px;
                    box-sizing: border-box;

                    .opions-content {
                        padding: 0 10px;

                        p {
                            margin-bottom: 10px;
                        }
                    }

                    .difficulty {
                        padding: 10px;
                        background: #f8f8f8;
                        display: flex;
                        justify-content: space-between;

                        a {
                            margin-left: 20px;
                            color: #3bafda;
                        }
                    }

                    .parse {
                        padding: 0 10px;
                    }
                }

                .item:hover {
                    border: 1px solid #3bafda;
                }
            }
        }
    }

    .pagination {
        text-align: center;
    }

    p {
        margin: 0;
        padding: 0;
    }
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
    background: #3bafda;
    color: #3bafda;
}

::v-deep .el-radio__input.is-checked+.el-radio__label {
    color: #3bafda;
}
</style>